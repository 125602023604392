import { StoresList } from '@killbillsdev/killbills-ui/lib/components/StoresList';
import { Grid } from "@mui/material";
import { styles } from "./styles";

export const LeftPanel = ({lat,long,setLat,setLong,mobile}:any) => {
  
  const handleStoreClick = async (_storeId:any, coord:any)=> {

    if (lat === coord.coordinates[1] && long === coord.coordinates[0]) {
      await  setLat(0);
      await  setLong(0);
    } else {
        setLat(coord.coordinates[1]);
        setLong(coord.coordinates[0]); 
    }
    setLat(coord.coordinates[1]);
    setLong(coord.coordinates[0]); 
    }
    if (mobile === true) {
    return (
        <Grid item xs={4} style={styles.leftPanelContainerMobile}>
            <StoresList api_url={import.meta.env.VITE_REACT_APP_STORE_SERVICE_URL}
            onClick={handleStoreClick}/>
        </Grid>
    );
    }
    else{
        return (
            <Grid item xs={4} style={styles.leftPanelContainer}>
                <StoresList api_url={import.meta.env.VITE_REACT_APP_STORE_SERVICE_URL}
                onClick={handleStoreClick}/>
            </Grid>
        );
    }

};