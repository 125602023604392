import '../App.css'

export const styles = {
    mapContainer: {
        display: 'flex',
        marginTop: '10vh',
        padding: '2vh',
        justifyContent:'center'
    },
    mapContainerMobile: {
        display: 'flex',
        marginTop: '0vh',
        marginLeft: '0vw',
        width: '100vw',
        height: '94vh',
    },
    leftPanelContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'top',
        marginTop: '10vh',
        padding: '2vh',
        height: '65vh',
    },
    leftPanelContainerMobile: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'top',
        marginTop: '1vh',
        height: '75vh',
    },
    drawerRootStyle: {
        height: '100%',
    },
    pullerBox: {
        width: 30,
        height: 4,
        backgroundColor: 'white',
        //background:'linear-gradient(135deg, rgb(83, 222, 206), rgb(48, 246, 152) )',
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)',
    },
    styledBox: {
        position: 'absolute',
        top: '-55px',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        visibility: 'visible',
        right: 0,
        left: 0,
        background: 'linear-gradient(135deg, #120D27,#120D27)',
    },
    drawerGlobal: {
        '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(75% - ${36})`,
            overflow: 'visible',
            backgroundColor: 'rgb(19,14,34,0.55)',
        },
    },
    drawerTypography: {
        p: 2,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: '16px',
        textAlign: 'center',
    },
}
