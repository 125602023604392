const translations = {
    'fr': {
        translation: {
            individuals: 'Particuliers',
            signs:'Enseignes',
            banks: 'Banques',
            ask_demo: 'Demander une démo',
            help: 'Aide',
            cgu: 'Conditions Générales d\'Utilisation',
            privacy: 'Politique de confidentialité',
            blog:'Blog',
            contact:'Contact',
            for_you:'Pour Vous !'.toUpperCase(),
            join_us:'Nous rejoindre',
            follow_us:'Suivez-nous !',
            copyright:`© ${new Date().getFullYear()} - Tous droits réservés`,
            footer:{
                subtitle:'Vos tickets de caisse à portée de doigts',
            },
            inview:'inview',
            stores_list:{
                permanent_item:{
                    title:"Parlez en autour de vous ! ",
                    subtitle:"KillBills - 25 Rue de la Reynie, 75001 Paris FRANCE - AGEC 2023 - 🧾",
                    more_results:"Plus de résultats ",
                    inview:'Inview',
                }
            },
            please_wait: 'Patientez',
            JSONPARTNER: 'JSON du partenaire',
            update_password: 'Mettre à jour',
            set_new_password: 'Changer le mot de passe',
            minLengthRule : 'Le mot de passe doit contenir 8 charactère.',
            retype_password: 'Saisir le mot de passe de nouveau.',
            specialCharRule: 'Le mot de passe doit contenir un charactère spécial.',
            hasNumberRule: 'Le mot de passe doit contenir un chiffre.',
            capitalRule: 'Le mot de passe doit contenir une lettre en majuscule.',
            passwordMatch: 'Les deux mots de passes sont identiques.',
            error_404: 'La page demandée n\'existe pas ou a été supprimée',
            role_user_label: 'Utilisateur',
            role_admin_label: 'Administrateur',
            role_owner_label: 'Propriétaire',
            choose_a_type: 'Choisir un type',
            choose_an_operator: 'Choisir un opérateur',
            add_a_condition: 'Filtre',
            search: 'recherchez un nom, une adresse...',
            reset_search: 'Réinitialiser',
            Field: 'Champs',
            Value: 'Value',
            Remove: 'Supprimer',
            Search_by_id: 'Chercher par ID',
            'By id': 'Par ID',
            'Query builder': 'Constructeur de requêtes',
            'yes': 'Oui',
            'no': 'Non',
            column_POS_label: 'POS ',
            // field_query_select_label: 'Sélection',
            field_user_label: 'Utilisateur',
            field_owner_label: 'Propriétaire',
            field_organization_label: 'Organisation',
            field_role_label: 'Rôle',
            field_platform_label: 'Plateforme',
            field_membership_label: 'Adhérant',
            field_bank_label: 'Banque',
            field_merchant_label: 'Merchant',
            field_partner_payload_partner_label: 'Partenaire :',
            field_partner_payload_dbId_label: 'ID en BDD',
            field_partner_payload_status_label: 'Statut :',
            modal_confirm_delete_partnerspayload_title: 'Supprimer le payload ?',
            modal_confirm_delete_partnerspayload_text: 'Cette action est irreversible',
            button_delete_partnerspayload_label: 'Supprimer',

            // field_query_select_placeholder: 'Sélectionnez votre élément...',
            field_user_placeholder: 'Sélectionnez un utilisateur',
            field_owner_placeholder: 'Sélectionnez le propriétaire',
            field_organization_placeholder: 'Sélectionnez une organisation',
            field_role_placeholder: 'Sélectionnez le rôle',
            field_platform_placeholder: 'Sélectionnez une plateforme',
            field_membership_placeholder: 'Sélectionnez un membre',
            field_bank_placeholder: 'Sélectionnez une banque',
            field_merchant_placeholder: 'Sélectionnez un marchand',
            field_id_label: 'ID',
            field_id_status: 'Status',
            field_id_tag: 'Tag',
            field_id_createdAt: 'Date Création',

            //field_select_label:
            field_visa_label: 'Visa',
            field_mastercard_label: 'MasterCard',
            field_cb_label:'CB',
            field_americanexpress_label: 'American Express',
            field_empty_label: 'Aucun',

            create_bank: 'Créer une banque',
            create_membership: 'Créer un lien banque - utilisateur',

            column_id_label: 'Identifiant',
            column_email_label: 'Email',
            column_firstBankingNetwork_label: '1er Réseau Monétique',
            column_secondBankingNetwork_label: '2nd Réseau Monétique',
            column_lastname_label: 'Nom',
            column_firstname_label: 'Prénom',
            column_name_label: 'Nom',
            column_store_id_label: 'ID du Magasin',
            column_store_name_label: 'Magasin',
            column_stat_receipts_label: 'Nombre de reçus',
            column_store_address_label: 'Adresse du Magasin',
            column_address_label: 'Adresse',
            column_store_merchant_name_label: 'Nom du Marchand',
            column_partnerName_label: 'Nom du POS',
            column_merchant_name_label: 'Nom du marchand',
            column_merchant_label: 'Marchand',
            column_amount_label: 'Montant',
            column_currency_label: 'Devise',
            column_created_at_label: 'Date de création',
            column_create_date_label: 'Date de création',
            column_updatedAt_label: 'Date de modification',
            column_date_label: 'Date d\'impression',
            column_update_date_label: 'Heure',
            column_actions_label: 'Actions',
            column_user_label: 'Utilisateur',
            column_organization_label: 'Organisation',
            column_code_label: 'Code',
            column_role_label: 'Rôle',
            column_bank_label: 'Banque',
            column_reference_label: 'Référence',
            column_transactions_label: 'Transactions',
            column_receipts_label: 'Reçus',
            column_store_label: 'Magasin',
            column_external_reference_label: 'Référence externe',
            column_owner_label: 'Propriétaire',
            column_paymentName_label:'Nom du Paiement TPE',
            column_siret_label: 'Siret',
            column_created_consumer_label: 'Membre depuis',
            column_partner_payload_partner_label: 'Partenaire',
            column_partner_payload_status_label: 'Statut',
            column_matches_amount: 'Montant',
            column_createdAt_label: 'Date',

            total_result_label: 'Total de résultats',
            select_table_label: 'Table',
            select_field_label: 'Champs',
            send_forgot_password: 'Récupérer mot de passe',
            back: 'Retour',
            recoveryCode: 'Code de récupération',
            submit: 'Envoyer',
            recovery_password_title: 'Récupération mot de passe',
            recovery_code_title: 'Réinitialisation du mot de passe',
            new_password: 'Nouveau mot de passe',
            confirm_new_password: 'Confirmation du nouveau mot de passe',


            THERE_IS: 'Il y a ',
            MINUTES: 'minutes',
            HOURS: 'heures',
            screen_demobanks_label: 'Compte chèques',
            screen_demobank_label: 'Reçu n°xxxx',
            screen_home_label: 'Accueil',
            screen_receipts_label: 'Reçus',
            screen_receipt_label: 'Reçu n°{{id}}',
            screen_users_label: 'Utilisateurs',
            screen_user_label: 'Utilisateur {{id}}',
            screen_banks_label: 'Banques',
            screen_bank_label: 'Banque {{id}}',
            screen_stores_label: 'Magasins',
            screen_store_label: 'Magasin {{id}}',
            screen_partners_payloads_label: 'Payloads',
            screen_partner_payload_label: 'Payload {{id}}',
            screen_transactions_label: 'Transactions',
            screen_transaction_label: 'Transaction {{id}}',
            screen_merchants_label: 'Marchands',
            screen_merchant_label: 'Marchand {{id}}',
            screen_matches_label: 'Matchs',
            screen_match_label: 'Match {{id}}',
            screen_organizations_label: 'Organisations',
            screen_organization_label: 'Organisation {{id}}',
            screen_platforms_label: 'Plateformes',
            screen_platform_label: 'Plateforme {{id}}',
            screen_tokens_label: 'Jetons de sécurité',
            screen_token_label: 'Jeton de sécurité {{id}}',
            screen_hmac_label: 'Liste des Hmac',
            screen_memberships_label: 'Droits Utilisateurs',
            screen_membership_label: 'Membre',
            screen_user_profile_label: 'Mes informations',
            screen_user_keys_label: 'Mes clés',
            screen_consumers_label: 'Consommateurs',
            screen_consumer_label: 'Consommateur',
            screen_payments_label: 'Paiements',
            screen_payment_label: 'Paiement',
            screen_denied_label: 'Accès non autorisé, vous ne disposez pas des bons droits. Contactez votre administateur.',

            screen_generic_list_table_empty: 'Aucun élément disponible.',
            screen_users_list_table_empty: 'Aucun utilisateur disponible.',
            screen_merchants_list_table_empty: 'Aucun marchand disponible.',

            menu_demobanks_label: 'Demo Banque',
            menu_users_label: 'Utilisateurs',
            menu_receipts_label: 'Reçus',
            menu_merchant_payments_label: 'Paiements',
            menu_transactions_label: 'Transactions',
            menu_banks_label: 'Banques',
            menu_demobank_label: 'Demo Bank',
            menu_stores_label: 'Magasins',
            menu_merchants_label: 'Marchands',
            menu_matches_label: 'Matchs',
            menu_platforms_label: 'Plateformes',
            menu_organizations_label: 'Organisations',
            menu_tokens_label: 'Jetons de Sécurité',
            menu_hmac_label:'Clés HMAC' ,
            menu_memberships_label: 'Droits Utilisateurs',
            menu_consumers_label: 'Consommateurs',
            menu_partner_payload_label: 'Payloads des partenaires',
            menu_search_screen_label: 'Rechercher des Reçus',
            menu_search_label: 'Rechercher',
            menu_section_activity_title: 'Activité',
            menu_section_settings_title: 'Paramétrages',
            menu_section_merchants_title: 'Marchands',
            menu_section_banks_title: 'Banques',
            menu_section_organizations_title: 'Organisations',
            menu_create_partner_label: 'Créer un partenaire',
            create_partner_bank: 'Créer une banque',
            create_partner_bank_subtitle: 'Remplissez le formulaire ci-dessous pour créer une banque',
            create_partner_membership: 'Créer une adhésion',
            create_partner_membership_subtitle: 'Remplissez le formulaire ci-dessous pour créer une adhésion',

            id: 'ID :',
            lastName: 'Nom :',
            firstName: 'Prénom :',
            email: 'Email :',
            name: 'Nom :',
            consumer: 'Consommateur :',
            merchantName: 'Nom de l\'entreprise :',
            storeName: 'Nom du magasin :',
            createdAt: 'Date de création :',
            updatedAt: 'Heure :',
            date: 'Date d\'impression :',
            store: 'ID magasin :',
            transactionConsumer: 'ID consommateur :',
            payloadId: 'ID payload :',
            amount: 'Montant :',
            reference: 'Référence :',
            payment: 'Paiement :',
            bankName: 'Nom de la banque :',
            currency: 'Devise :',
            covers: 'Nombre de couverts :',
            taxAmount: 'Montant de la taxe :',
            taxes: 'Taxe :',
            taxDescription: 'Type de taxe :',
            match: 'Match :',
            matchable: 'Matchable :',
            matchable_value: 'Prêt à matcher',
            non_matchable_value: 'Non matchable',
            address: 'Adresse :',
            externalReference: 'Référence :',
            degradedReference: 'Référence dégradée :',
            lastFour: '4 derniers chiffres :',
            bin: 'BIN :',
            partnerName: 'POS :',
            scheme: 'Scheme :',
            paymentName: 'Nom du terminal :',
            headerTicket: 'Header ticket :',
            footerTicket: 'Footer ticket :',
            status: 'Status :',
            tag: 'Tag :',
            owner_id: 'ID propriétaire :',
            code: 'Code :',
            createdAt_tab_label: 'Date de création :',
            receiptId_tab_label: 'Reçu ID :',
            receiptId: 'Reçu ID :',
            transactionId_tab_label: 'Transaction ID :',
            public_tab_label: 'Public :',
            editStore_public:'Public',
            transaction: 'ID transaction :',
            receipt: 'ID reçu :',
            userFirstName: 'Prénom :',
            userLastName: 'Nom :',
            role: 'Rôle :',
            siret: 'Siret :',
            siren: 'Siren :',
            userEmail: 'Email :',
            organizationName: 'Nom de l\'organisation :',
            organization: 'Organisation :',
            statStores: 'Nombre de magasins :',
            statReceipts: 'Nombre de reçus :',
            statTransactions: 'Nombre de transactions :',
            stat_matches: 'Nombre de matchs :',
            statMatches: 'Nombre de matchs :',
            statConsumers: 'Nombre de consommateurs :',
            location: 'GPS :',
            Hmac: 'Hmac',
            hmac: 'Hmac :',
            menu_api_doc_label: 'Documentation API',
            firstBankingNetwork: '1er Réseau Monétique',
            secondBankingNetwork: '2nd Réseau Monétique',

            //#Operators
            ids: 'Liste d\'identifiants',
            in: 'Contient',
            eq: 'Égal à',
            lt: 'Inférieur à',
            gt: 'Supérieur à',
            lte: 'Inférieur ou égal à',
            gte: 'Supérieur ou égal à',
            prefix: 'Préfixe',
            exists: 'Existe',
            wildcard: 'Caractères génériques',
            regex: 'Regex',
            range: 'Intervalle - [x]',
            rangelt: 'Intervalle minimum exclu - ]< x]',
            rangegt: 'Intervalle maximum exclu - [x <[',
            rangegtlt: 'Intervalle borné - ]x[',

            //#Types
            string: 'Chaine de caractères',
            int: 'Nombre entier',
            boolean: 'Booléen',
            float: 'Nombre décimal',

            field_start_date_time_label: 'Date de début',
            field_end_date_time_label: 'Date de fin',
            field_placeholder_label: 'Champs',
            operator_placeholder_label: 'Operateur',
            value_placeholder_label: 'Valeur',
            type_placeholder_label: 'Type',
            field_field_label: 'Champs',
            field_operator_label: 'Opérateur',
            field_value_label: 'Valeur',
            field_type_label: 'Type',
            type_bank_label: 'Banque',
            type_merchant_label: 'Marchand',

            button_display_label: 'Afficher',
            button_add_label: 'Ajouter',

            form_edit_user_title: 'Modification de l\'utilisateur',
            form_edit_user_subtitle: 'Vous pouvez mettre à jour les données de l\'utilisateur ci-dessous',

            user_menu_profile_label: 'Mes informations',

            modal_confirm_delete_bank_title: 'Confirmer la suppression',
            modal_confirm_delete_bank_text: 'Êtes-vous certain de vouloir supprimer cette banque ?',
            modal_confirm_delete_store_title: 'Confirmer la suppression',
            modal_confirm_delete_store_text: 'Êtes-vous certain de vouloir supprimer ce magasin ?',
            modal_confirm_delete_merchant_title: 'Confirmer la suppression',
            modal_confirm_delete_merchant_text: 'Êtes-vous certain de vouloir supprimer ce marchand ?',
            modal_confirm_delete_organization_title: 'Confirmer la suppression',
            modal_confirm_delete_organization_text: 'Êtes-vous certain de vouloir supprimer cette organisation ?',
            modal_confirm_delete_user_title: 'Confirmer la suppression',
            modal_confirm_delete_user_text: 'Êtes-vous certain de vouloir supprimer cet utilisateur ?',
            modal_confirm_delete_membership_title: 'Confirmer suppression',
            modal_confirm_delete_membership_text: 'Êtes-vous certain de vouloir supprimer ce membre ?',
            modal_confirm_delete_receipt_title: 'Confirmer suppression',
            modal_confirm_delete_receipt_text: 'Êtes-vous certain de vouloir supprimer ce reçu ?',
            modal_confirm_delete_transaction_title: 'Confirmer suppression',
            modal_confirm_delete_transaction_text: 'Êtes-vous certain de vouloir supprimer cette transaction ?',
            modal_confirm_delete_match_title: 'Confirmer suppression',
            modal_confirm_delete_match_text: 'Êtes-vous certain de vouloir supprimer ce match ?',
            modal_confirm_delete_payload_title: 'Confirmer suppression',
            modal_confirm_delete_payload_text: 'Êtes-vous certain de vouloir supprimer cette payload ?',
            modal_confirm_delete_consumer_title: 'Confirmer suppression',
            modal_confirm_delete_consumer_text: 'Êtes-vous certain de vouloir supprimer ce consommateur ?',

            button_delete_membership_label: 'Supprimer',
            button_delete_user_label: 'Supprimer',
            button_delete_bank_label: 'Supprimer',
            button_delete_store_label: 'Supprimer',
            button_delete_merchant_label: 'Supprimer',
            button_delete_organization_label: 'Supprimer',
            button_delete_receipt_label: 'Supprimer',
            button_delete_transaction_label: 'Supprimer',
            button_delete_match_label: 'Supprimer',
            button_delete_payload_label: 'Supprimer',
            button_delete_consumer_label: 'Supprimer',
            button_link_organization_label: 'Voir l\'organisation',
            button_link_transaction_label: 'Voir la transaction',
            button_link_receipt_label: 'Voir le reçu',
            button_link_edit_label: 'Modifier',
            button_link_edit_password_label: 'Modifier mot de passe',
            button_link_json_label: 'JSON',
            button_link_merchant_label: 'Voir le marchand',
            button_link_bank_label: 'Voir la banque',
            button_link_demo_bank_home_label: 'Accueil',
            button_link_demo_bank_demo_label: 'Épargne',
            button_link_demobanks_label: 'Compte chèques',

            tab_organization_infos_title : 'Détails',
            tab_organization_members_title : 'Membres',
            tab_merchant_infos_title: 'Détails',
            tab_merchant_stores_title: 'Magasins',
            tab_bank_infos_title: 'Détails',
            tab_bank_consumers_title: 'Consommateurs',
            tab_bank_transactions_title: 'Transactions',
            tab_bank_matches_title: 'Matchs',

            membership_user_label: 'Utilisateur',
            membership_admin_label: 'Administrateur',
            membership_owner_label: 'Propriétaire',

            homeScreen_transactions: 'Total Transactions',
            homeScreen_receipts: 'Total Reçus',
            homeScreen_matches: 'Total Matchs',
            homeScreen_titleBank: '42',
            homeScreen_subtitleBank: 'Nb de Banques',
            homeScreen_titleMerchant: '256',
            homeScreen_subtitleMerchant: 'Nb Marchands',
            homeScreen_titleStore: '3025',
            homeScreen_subtitleStore: 'Nb de Boutiques',
            homeScreen_titleAppz: '198',
            homeScreen_subtitleAppz: 'Nb de logiciels de caisse partenaires',
            homeScreen_customers: 'Clients',
            homeScreen_newCustomers: 'Nouveaux Clients',
            homeScreen_transactionsGoal: 'Objectif des Transactions',
            homeScreen_matchesGoal: 'Objectif des Matchs',
            homeScreen_customersGoal: 'Objectif Clients',
            homeScreen_stats_one: 'nombre de vente début d\'année',
            homeScreen_stats_two: 'nombre de vente milieu d\'année',
            homeScreen_stats_three: 'nombre de vente fin d\'année',
            homeScreen_first_merchant_label: 'Premier marchand',
            homeScreen_first_store_label: 'Premier magasin',
            homeScreen_transactions_label: 'Nombre de transactions',
            homeScreen_matches_label: 'Nombre de matchs',
            homeScreen_receipts_label: 'Nombre de reçus',
            homeScreen_users_label: 'Nombre d\'utilisateurs',
            homeScreen_transactions_per_receipts_label: 'Ratio transactions/reçus',
            homeScreen_receipts_per_transactions_label: 'Ratio reçus/transactions',
            homeScreen_matches_per_receipts_percent_label: 'Ratio matchs/reçus',
            homeScreen_matches_per_transactions_percent_label: 'Ration matchs/transactions',
            homeScreen_store_counter: 'Nombre de magasins',
            homeScreen_stores_goal_label: 'Objectif 10.000 magasins partenaires (+9000%)',
            homeScreen_stores_label: 'Nombre de magasins',
            homeScreen_receipts_chart_label: 'Reçus',
            homeScreen_transactions_chart_label: 'Transactions',
            homeScreen_receipts_goal_label: 'Objectif 1.000.000 reçus',
            homeScreen_transactions_goal_label: 'Objectif 10.000 transactions',
            receipts_on_last_24: 'Reçus sur les dernières 24 heures',
            transactions_on_last_24: 'Transactions sur les dernières 24 heures',
            transactions_percentage_increase: 'de transactions',
            receipts_percentage_increase: 'de reçus',
            matches_on_last_24: 'Matchs sur les dernières 24 heures',
            matches_percentage_increase: 'de matchs',

            editStore_address: 'Adresse',
            editStore_siret: 'SIRET',
            editStore_paymentName: 'Nom du Paiement TPE',
            editStore_headerTicket: 'Header du ticket',
            editStore_footerTicket: 'Footer du ticket',
            editStore_location: 'Coordonées GPS',
            editTransaction_amount: 'Montant',
            editTransaction_storeName: 'Nom du magasin',
            editTransaction_date: 'Date',
            editOrganization_type: 'Type',
            type: 'Type :',
            screen_search_input: 'Saisir votre requête',
            /*permet au composant Textfield de ne pas afficher un helper*/ empty_helper: '',

            security_forgot_password_title: "Récupération mot de passe",
            security_forgot_password_details: "Saisissez votre email afin de récupérer votre mot de passe",
            security_new_password_title: 'Réinitialisation mot de passe',
            security_new_password_details: 'Saisissez votre code de récupération et nouveau mot de passe',

            footer_home_link_label: 'Accueil',
            footer_services_link_label: 'Services',
            footer_about_us_link_label: 'À propos de nous',
            footer_team_link_label: 'L\'équipe',
            footer_privacy_terms_link_label: 'Confidentialité et modalités',
            footer_phone_label: '118-218',
            footer_email_label: 'killbills@killbills.com',
            footer_address_label: '62 rue Jean Jacques Rousseau',
            footer_zipCode_label: '75001',
            footer_city_label: 'Paris',
            footer_copyright_label: '© 2022  KillBills',
            footer_description_label: 'Les tickets de caisse, autrement.',
            footer_facebook_label: 'https://www.facebook.fr',
            project_name: '',
        }
    },
    'en-EN': {
        translation: {

        }
    }
}
export default translations