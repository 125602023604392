import { PublicStoresMap } from '@killbillsdev/killbills-ui/lib/components/PublicStoresMap';
import { CustomMap } from '@killbillsdev/killbills-ui/lib/components/CustomMap';
import { KbLoader } from '@killbillsdev/killbills-ui/lib/components/atoms/mapAtoms/KbLoader';
import { Grid } from '@mui/material';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const Map = ({ lat, long, mobile = false }: any) => {

    const [storesData, setStoresData]:any = useState(null);
 
    useEffect(() => {
        
        const fetchStoresData = async () => {
            try {
                const storesData1 = await axios.post('https://3rh2vicyak.execute-api.eu-west-3.amazonaws.com/prod/getStores?limit=300000000');
                setStoresData(storesData1?.data);

            } catch (error) {
                console.error(
                    "Une erreur s'est produite lors de la récupération des données des magasins :",
                    error
                )
            }
        }
        fetchStoresData()
    }, [])
    if (mobile) {
        return (
            <Grid item xs={12} style={{...styles.mapContainerMobile, justifyContent:'center'}}>
                {storesData && storesData !== null ? (
                    <CustomMap
                        googleApiKey={
                            import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY
                        }
                        storesData={storesData}
                        lat={lat}
                        long={long}
                        zoom={lat ? 15 : 5}
                    />
                ) : (
                    <KbLoader />
                )}
            </Grid>
        )
    } else {
        return (
            <Grid item xs={8} style={styles.mapContainer}>
                {storesData && storesData !== null ? (
                    <PublicStoresMap
                        googleApiKey={
                            import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY!
                        }
                        storesData={storesData}
                        lat={lat}
                        long={long}
                        zoom={lat ? 15 : 5}
                    />
                ) : (
                    <KbLoader />
                )}
            </Grid>
        )
    }
}
