import { Global } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { Box, Typography } from '@mui/material'
import { styles } from './styles'

const drawerBleeding = 36

export const DrawerMobile = ({ drawerOpen, setDrawerOpen, content }: any) => {
    return (
        <div style={styles.drawerRootStyle}>
            <CssBaseline />
            <Global styles={styles.drawerGlobal} />
            <SwipeableDrawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box sx={styles.styledBox}>
                    <Box sx={styles.pullerBox}></Box>
                    <Typography sx={styles.drawerTypography}>
                        OU PAYER ?
                    </Typography>
                </Box>
                {content}
            </SwipeableDrawer>
        </div>
    )
}
