/* eslint-disable @typescript-eslint/no-unused-vars */
import { Navbar } from '@killbillsdev/killbills-ui/lib/components/Navbar'
import { Footer } from '@killbillsdev/killbills-ui/lib/components/Footer'
import { Map, LeftPanel, DrawerMobile } from './Components/'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Hidden } from '@mui/material'

function App() {
    const [lat, setLat] = useState(null as any)
    const [long, setLong] = useState(null as any)
    const [drawerOpen, setDrawerOpen] = useState(false)
  
    useEffect(() => {
        setDrawerOpen(false)
    }, [lat, long])

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2} display={{ xs: 'none', sm: 'flex' }}>
                <LeftPanel
                    lat={lat}
                    long={long}
                    setLat={setLat}
                    setLong={setLong}
                    mobile={false}
                />
                <Map lat={lat} long={long} />
                <Footer />
            </Grid>
            <Grid container spacing={0} display={{ sm: 'none', xs: 'flex' }} >
                <Map lat={lat} long={long} mobile={true} />
            </Grid>
            <Hidden smUp>
                <DrawerMobile
                    lat={lat}
                    long={long}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    setLat={setLat}
                    setLong={setLong}
                    content={
                        <LeftPanel
                            lat={lat}
                            long={long}
                            setLat={setLat}
                            setLong={setLong}
                            mobile={true}
                        />
                    }
                />
            </Hidden>
        </Box>
    )
}

export default App
